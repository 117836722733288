.likedislike{ 
    height: 20px;
    width: 20px;
    border-radius: 0;
    margin: 0 0.5em;
}

.flex-like-container{
    display: flex;
    justify-content: space-between;
}

.flex-boxgift{
    display: flex;

}