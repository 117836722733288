.loader-container{
        // background: url('../../Images/loaderGif.gif') no-repeat center center;
        /* max-width: 100%;
        max-height: 100%; */
        display: flex;
        justify-content: center;
        // transform: scale(0.3);
        // z-index: 9999999;
}

.loader-img{
        max-width: 300px;
}