.ql-container.ql-snow{
        border: none;
}

.quill-tag {
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        border-radius: 3px;
        padding: 2px 4px;
        color: #BD4533;
        font-style: italic;
      }
      