.mention {
    background: #fef2f2;
    color: #bd4533;
    font-size: 0.8rem;
    font-weight: bold;
    border-radius: 15px;
    padding: 0.2rem 0.5rem;
    white-space: nowrap;
    border: 1px solid #bd4533; /* Added border color red */
  }
  
  .suggestion-list {
    padding: 0.2rem;
    border: 2px solid rgba(0, 0, 0, 0.1);
    font-size: 0.8rem;
    font-weight: bold;
  }
  
  .suggestion-list__item {
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
  }
  
  .suggestion-list__item:last-child {
    margin-bottom: 0;
  }
  
  .suggestion-list__item.is-selected,
  .suggestion-list__item:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .suggestion-list__item.is-empty {
    opacity: 0.5;
  }
  
  .tippy-box[data-theme~=dark] {
    background-color: black;
    padding: 0;
    font-size: 1rem;
    text-align: inherit;
    color: white;
    border-radius: 5px;
  }
  
  .menubar__button {
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .menubar__button:hover {
    background-color: #0056b3;
  }
  