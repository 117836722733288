
.editor-wrapper {
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 150px; // Set the maximum height you want for the editor content
  overflow-y: auto; // Enable vertical scrolling
}

@media screen and (min-width: 768px) {
  .editor-wrapper {
    max-height: 80px; // Set the maximum height for big screens
  }
}


.editor-content-wrapper {
  transition: height 0.3s ease; // Smooth transition for collapsing
}


.tiptap{
  height: 100%;
  border: none;
  margin-left:10px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  line-height: 1.8;

}

.ProseMirror:focus {
  outline: none;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  // font-weight: bold;
}
.editor {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 150px;
  font-family: Arial, sans-serif;
}

.ProseMirror {
  outline: none;
}

.ProseMirror p.is-empty::before {
  content: attr(data-placeholder);
  color: #aaa;
  float: left;
  height: 0;
  pointer-events: none;
}

// .hidden-autocomplete {
//   color: #aaa;
//   // background-color: yellow; /* Visible background for testing */
//   font-weight: bold; /* Bold the text */
// }

// .hidden-autocomplete {
//   opacity: 0.5;
//   color: #718096;
//   font-style: italic;
//   // position: absolute;
//   pointer-events: none;
//   cursor: pointer;
//   z-index: 1000;
// }

.hidden-autocomplete {
  // position: absolute; /* Ensure it is positioned correctly */
    opacity: 0.5;
  color: #718096;
    font-style: italic;

  z-index: 1000; /* Ensure it is above other content */
  cursor: pointer; /* Ensure it looks clickable */
}


/* autostyles.css */

// .autocomplete-suggestions {
//   position: absolute;
//   z-index: 1000;
//   // background: white;
//   // border: 1px solid #ddd;
//   border-radius: 4px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
//   max-height: 300px;
//   // overflow-y: auto;
//   // padding: 10px;
//   top: auto; /* No longer positioning from the top */
//   bottom: 100%; /* Position the bottom of the component at the top of the parent */
// }

.autocomplete-suggestions {
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  display: block; /* Ensure it's visible */
  visibility: visible; /* Ensure it's visible */
  opacity: 1; /* Ensure it's visible */
  
  top: auto; /* No longer positioning from the top */
  bottom: 100%; /* Position the bottom of the component at the top of the parent */
}


.autocomplete-item {
  padding: 8px 12px;
  cursor: pointer;
}

.autocomplete-item.active {
  background-color: #007bff;
  color: white;
}

.autocomplete-item:hover {
  background-color: #f0f0f0;
}

.autocomple-suggestions{
  position:absolute; 
}

.autocomplete-widget {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other content */
}
