/* styles.css */
.result {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .index1 {
    background-color: #d3f9d8; /* Light green */
  }
  
  .index2 {
    background-color: #d9e9ff; /* Light blue */
  }
  